import styled from "styled-components"

type contentWrapper = {
  backgroundColor?: string
  textColor?: string
}

type dualContentContainer = {
  maxHeight?: number
}

type contentContainer = {
  maxWidth?: string
  mobileMargin?: string
  desktopMargin?: string
}

type imgContainer = {
  mobileSrc?: string
  desktopSrc?: string
  mobileMargin?: string
  desktopMargin?: string
}

export const ContentWrapper = styled.div<contentWrapper>`
  color: ${({ textColor }) => (textColor ? textColor : "")};
  background: ${({
    theme: {
      main: { offWhite },
    },
  }) => offWhite};
  ${({ backgroundColor }) =>
    backgroundColor ? `background: ${backgroundColor};` : ""}
  padding: 40px 25px;

  // ---------- START - CLIMATE CHANGE SPECIFIC CODE ---------- \\

  .climate-content-slider {
    margin: 0 10px;
    max-width: 1016px;

    @media screen and (min-width: 960px) {
      margin: 0 auto;
    }

    .slick-dots {
      bottom: -45px;
    }

    .slick-list {
      margin-bottom: 25px;
    }

    li.slick-active {
      background: ${({ textColor }) => (textColor ? textColor : "")};
    }

    li.slick-active button:before {
      color: ${({ textColor }) => (textColor ? textColor : "")};
    }

    h2 {
      font-weight: bold;
      text-align: left;
      color: ${({ textColor }) => (textColor ? textColor : "")};

      @media screen and (min-width: 960px) {
        text-align: center;
      }
    }

    .climate-content-author {
      width: auto;
      float: right;
      margin-top: 15px;
      font-weight: bold;
      color: ${({
        theme: {
          main: { offBlack },
        },
      }) => offBlack};
    }
  }
  // ---------- END - CLIMATE CHANGE SPECIFIC CODE ---------- \\
`

export const ContentContainer = styled.div<contentContainer>`
  margin: ${({ mobileMargin }) => (mobileMargin ? mobileMargin : "0px auto")};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "")};
  position: relative;
  height: 100%;

  @media screen and (min-width: 960px) {
    margin: ${({ desktopMargin }) =>
      desktopMargin ? desktopMargin : "0px auto"};
  }

  > h2 {
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: 14px;
    color: ${({
      theme: {
        main: { offBlack },
      },
    }) => offBlack};
    text-align: left;
  }

  > button {
    &:first-of-type {
      margin-top: 20px;
    }
    &:last-of-type {
      margin-bottom: 15px;
    }
  }

  // ---------- START - CLIMATE CHANGE SPECIFIC CODE ---------- \\
  .why-sub2 {
    @media screen and (min-width: 960px) {
      position: absolute;
      bottom: 45px;
      left: 0;
      right: 0;
    }
  }
  // ---------- END - CLIMATE CHANGE SPECIFIC CODE ---------- \\

  // ---------- START - CONTACT PAGE SPECIFIC CODE ---------- \\
  .contact-send-btn {
    @media screen and (min-width: 585px) {
      margin-bottom: 80px !important;
      margin-right: 0;
      margin-left: auto;
      width: 212px;
    }
  }

  .contact-form-flex-wrapper {
    display: flex;
    max-width: 805px;
    flex-direction: column;
    margin-top: 10px;

    @media screen and (min-width: 585px) {
      flex-direction: row;
      flex-wrap: wrap;
      column-gap: 25px;
      margin-top: 80px;
    }

    input {
      width: calc(50% - 12.5px);

      @media screen and (max-width: 584px) {
        width: 100%;
      }
    }
  }
  // ---------- END - CONTACT PAGE SPECIFIC CODE ---------- \\
`

export const DualContentContainer = styled.div<dualContentContainer>`
  display: flex;
  flex-flow: row wrap;

  > div {
    width: 100%;

    @media screen and (min-width: 960px) {
      width: 50%;
    }
  }
`

export const ImgContainer = styled.img<imgContainer>`
  width: 100%;
  content: ${({ mobileSrc }) => (mobileSrc ? `url(${mobileSrc})` : "")};
  margin: ${({ mobileMargin }) => (mobileMargin ? mobileMargin : "0px auto")};

  @media screen and (min-width: 960px) {
    margin: ${({ desktopMargin }) =>
      desktopMargin ? desktopMargin : "0px auto"};
    content: ${({ desktopSrc }) => (desktopSrc ? `url(${desktopSrc})` : "")};
  }
`
