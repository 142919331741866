import React from "react"
import { ContentWrapper, ContentContainer } from "./style"

interface Text {
  text: string
}

type props = {
  id?: string
  children: JSX.Element | JSX.Element[]
  backgroundColor?: string
  textColor?: string
  maxWidth?: string
  mobileMargin?: string
  desktopMargin?: string
}

const index = ({
  id,
  children,
  maxWidth,
  mobileMargin,
  desktopMargin,
  backgroundColor,
  textColor,
}: props) => {
  return (
    <ContentWrapper
      id={id}
      backgroundColor={backgroundColor}
      textColor={textColor}
    >
      <ContentContainer
        maxWidth={maxWidth}
        mobileMargin={mobileMargin}
        desktopMargin={desktopMargin}
      >
        {children}
      </ContentContainer>
    </ContentWrapper>
  )
}

export default index
