import React, { useRef, useState } from "react"
import { Link } from "gatsby"
import Slider, { Settings } from "react-slick"
import Hero, { CarouselItem } from "../components/Layout/Hero"
import Content from "../components/Layout/Content"
import bioBased from "../../static/img/bio-based-plastics.png"
import pulpNpaper from "../../static/img/pulp-and-paper.png"
import textiles from "../../static/img/textiles.png"
import foods from "../../static/img/foods.png"
import { TanghoTheme } from "../components/Themes"
import {
  Heading,
  Subheading,
  customHeading,
} from "../components/Layout/Hero/style"
import SEO from "../components/SEO"

/**
 * Handles smooth scrolling to a specific location in a page based on the provided id.
 * @param id A string identifier for location of the page to be scrolled to.
 */
const smoothScrollTo = (id: string) => {
  if (document) {
    document
      .getElementById(`wwd_cs_${id}`)
      ?.scrollIntoView({ behavior: "smooth" })
  }
}

const {
  main: { bottleGreen },
} = TanghoTheme

const carouselSlide: CarouselItem[] = [
  {
    backgroundImg: "OByKp6IG-sQ",
    isVideo: true,
    heading: {
      mobileFontAlign: "center",
      desktopFontAlign: "center",
      text: "BIO-BASED PLASTICS",
      mobileMargin: "150px 0px 70px 0px",
      desktopMargin: "340px 0 0 0",
      fontColor: "#FCFBFB",
      desktopFontSize: "96px",
    },
    btnList: [
      {
        label: "Learn More",
        mobileFontSize: "18px",
        desktopFontSize: "24px",
        onClick: () => smoothScrollTo("0"),
      },
    ],
  },
  {
    backgroundImg: "NhqAb0Z4VJs",
    isVideo: true,
    heading: {
      mobileFontAlign: "center",
      desktopFontAlign: "center",
      text: `PULP & PAPER`,
      mobileMargin: "150px 0px 70px 0px",
      desktopMargin: "340px 0 0 0",
      fontColor: "#FCFBFB",
      desktopFontSize: "96px",
    },
    btnList: [
      {
        label: "Learn More",
        mobileFontSize: "18px",
        desktopFontSize: "24px",
        onClick: () => smoothScrollTo("1"),
      },
    ],
  },
  {
    backgroundImg: "DlRcYbotXFc",
    heading: {
      mobileFontAlign: "center",
      desktopFontAlign: "center",
      text: "TEXTILES",
      mobileMargin: "150px 0px 70px 0px",
      desktopMargin: "340px 0 0 0",
      fontColor: "#FCFBFB",
      desktopFontSize: "96px",
    },
    isVideo: true,
    btnList: [
      {
        label: "Learn More",
        mobileFontSize: "18px",
        desktopFontSize: "24px",
        onClick: () => smoothScrollTo("2"),
      },
    ],
  },
  {
    backgroundImg: "18JBCcJcka4",
    isVideo: true,
    heading: {
      mobileFontAlign: "center",
      desktopFontAlign: "center",
      text: "FOODS",
      mobileMargin: "150px 0px 70px 0px",
      desktopMargin: "340px 0 0 0",
      fontColor: "#FCFBFB",
      desktopFontSize: "96px",
    },
    btnList: [
      {
        label: "Learn More",
        mobileFontSize: "18px",
        desktopFontSize: "24px",
        onClick: () => smoothScrollTo("3"),
      },
    ],
  },
]

const subheadingSettings: customHeading = {
  fontColor: "#020202",
  mobileFontAlign: "left",
  desktopFontAlign: "left",
  mobileFontSize: "14px",
  mobileLineHeight: 18,
  desktopFontSize: "24px",
  desktopLineHeight: 31,
  padded: "32px 0",
  maxWidth: "650px",
  desktopMargin: "16px 0 0 0",
  mobileMargin: "0 auto 0 auto",
}

const headingSettings: customHeading = {
  maxWidth: "650px",
  mobileFontSize: "36px",
  mobileLineHeight: 36,
  mobileFontAlign: "left",
  desktopFontSize: "64px",
  desktopLineHeight: 64,
  desktopFontAlign: "left",
  mobileMargin: "auto",
  desktopMargin: "auto",
  padded: "32px 0 0 0",
}

const sliderSettings: Settings = {
  arrows: false,
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerPadding: "0",
  centerMode: true,
  useTransform: false,
  useCSS: false,
}

const content = [
  {
    key: 0,
    title: "BIO-BASED PLASTICS",
    whatDescription:
      "Our innovative technology mixes hemp fibers to create eco-friendly bio-based plastics. Aside from being lighter and stronger, our hemp-based plastics reduce carbon emissions throughout the entire supply chain.",
    whyDescription:
      "From fiber production to disposal, our process reduces carbon emissions throughout the supply chain",
  },
  {
    key: 1,
    title: "PULP & PAPER",
    whatDescription:
      "We have developed a modern and efficient process to transform hemp into pulp and pulp into packaging paper. Hemp culture is faster and more efficient than Eucalyptus and Pinus; it also requires less space, less water and less pesticides.",
    whyDescription:
      "Not only is the culture of hemp faster than that of trees such as Eucalyptus " +
      "and Pinus, it also requires less space, less water, and less pesticides",
  },
  {
    key: 2,
    title: "TEXTILES",
    whatDescription:
      "Hemp was historically used as a raw material for textile applications, we have developed and standardized a made to measure technique to adapt hemp textiles into our modern life.",
    whyDescription:
      "The culture and processing of hemp outshines any other raw material when " +
      "the conversation is sustainability and ecological practices. It produces more fiber and " +
      "less CO2 emissions than cotton, and consumes less water and energy than cotton, wool, " +
      "polyester, viscose, nylon, acrylic and polypropylene.",
  },
  {
    key: 3,
    title: "FOODS",
    whatDescription:
      "With large cultivation areas, we have access to a substantial quantity of hemp seeds, a well-known superfood rich in protein, omega-3, omega-6, fat and fibers. We have built a fully licensed facility to process our organic hemp seeds. Hemp seeds are healthy for us and for the environment, emitting less CO2 than most commodities.",
    whyDescription:
      "Hemp seeds are a well-known superfood; rich in protein, omega-3, omega-6, fat and fibers. " +
      "They are healthy for us and also for the environment, emitting less CO2 than other agricultural commodities like soybeans.",
  },
]

const WhatWeDo = () => {
  const [subSlider, setSubSlider] = useState()
  const [mainSlider, setMainSlider] = useState()

  interface ContentCarouselSlide {
    key: number
    title: string
    whatDescription: string
    whyDescription: string
  }

  const generateContent = (slide: ContentCarouselSlide) => {
    return (
      <div id={`wwd_cs_${slide.key}`} key={`wwd_cs_${slide.key}`}>
        <Heading {...headingSettings}>{slide.title}</Heading>
        <Subheading {...subheadingSettings}>
          {slide.whatDescription}
          <br />
          <br />
          <Link style={{ color: bottleGreen }} to="/contact">
            <strong>Contact us</strong>
          </Link>{" "}
          to request more information
        </Subheading>
      </div>
    )
  }

  return (
    <>
      <SEO
        title="Tangho - What We Do"
        description=""
        image={bioBased}
        url="/solutions"
      />
      <Hero
        subSlider={subSlider}
        setMainSlider={setMainSlider}
        carouselItems={carouselSlide}
        mobileHeight={432}
      />
      <Content>
        <Slider
          ref={(slider: any) => setSubSlider(slider)}
          asNavFor={mainSlider}
          {...sliderSettings}
        >
          {content.map((slide: ContentCarouselSlide) => generateContent(slide))}
        </Slider>
      </Content>
    </>
  )
}

export default WhatWeDo
